import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import { Link } from "gatsby"

const metaTags = {
  description: 'Browse the caregiver sitemap to find a full list of pages on the SUPPRELIN® LA (histrelin acetate) site.',
  keywords: 'Homepage',
  title: 'Sitemap'
}


const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>Sitemap</h1>
      </Col>
    </Row>
    <Row>
        <Col xs={12}>
            <ul className='brand-list'>
                <li><Link to='/'>Home</Link></li>
            </ul>
            <h2>About CPP</h2>
            <ul className='brand-list'>
                <li><Link to='/patients/what-is-central-precocious-puberty'>What Is CPP?</Link></li>
                <li><Link to='/patients/why-treat-central-precocious-puberty'>Why Treat CPP?</Link></li>
                <li><Link to='/patients/what-are-my-childs-treatment-options'>Treatment Options</Link></li>
            </ul>
            <h2>About SUPPRELIN<sup>&reg;</sup> LA</h2>
            <ul className='brand-list'>
              <li><Link to='/patients/what-is-supprelin-la'>About SUPPRELIN<sup>&reg;</sup> LA</Link></li>
              <li><Link to='/patients/5-good-reasons-to-ask'>Why SUPPRELIN<sup>&reg; </sup> LA?</Link></li>
              <li><Link to='/patients/insertion-and-aftercare'>Insertion &amp; Aftercare</Link></li>
              <li><Link to='/patients/removal-and-reinsertion'>Removal &amp; Reinsertion</Link></li>
              <li><Link to='/patients/potential-side-effects'>Potential Side Effects</Link></li>
            </ul>
            <h2>Copay Assistance</h2>
            <ul className='brand-list'>
              <li><Link to='/patients/shares-savings-program'>Savings</Link></li>
              <li><Link to='/patients/support-center'>Support Center &amp; <br/> Getting Started</Link></li>
            </ul>
            <h2>Resources</h2>
           <ul className='brand-list'>
            <li><Link to='/patients/caregiver-resources'>Videos Explaining <span className='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span></Link></li>
            <li><Link to='/patients/downloads-support'>Download Patient &amp; <br/>Caregiver Resources</Link></li>
            <li><Link to='/patients/caregiver-signup'>Sign Up for More Information</Link></li>
          </ul>
        </Col>
    </Row>
  </Layout>
)

export default IndexPage
